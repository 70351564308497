import { VENDOR_LISTS } from './vendor-collaboration';

// invitation flag feature
export const ENABLE_SHIFT_TIME_RECEPTION = false;
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_PARTNER_MODE = true;
export const ENABLE_GUEST_LIST = true;

export const ENABLE_GENERATOR_INVITATION = true;
export const ENABLE_CUSTOM_LINK = false;

// Giftcard flag feature
export const ENABLE_SEND_GIFT = false;
export const ENABLE_BANK_TRANSFER = false;
export const ENABLE_GIFT_CONFIRMATION = false;

// generator link flag feature
export const DISABLE_CUSTOM_LINK = false;
export const DISABLE_GENERATOR_INVITATION = false;
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = false;
export const ENABLE_BLACK_AND_WHITE = false;

// Special Features
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const ENABLE_QR_INVITATION = true; // set to `true` if QRCODE invitation
export const ENABLE_QR_NAME_VALIDATION = true;
export const MUSLIM_INVITATION = true; // change to `true` if you want to use muslim wording
export const DEFAULT_LANGUAGE = 'id'; // change to `en` if you want to set default to English
export const ENABLE_MULTI_LANGUAGE = false;
export const ENABLE_MUSIC_BACKSOUND = true;
export const ENABLE_DARKMODE_NOTIFICATION = true;
export const ENABLE_COVID_POPUP = false;
export const ENABLE_ARAB = true;
export const ENABLE_COLLABORATION = false;
export const SELECTED_VENDOR_COLLABORATION = VENDOR_LISTS['aksara']; // change 'aksara' to any of the vendor inside VENDOR_LISTS if want to use a new vendor, just copy and add under the last vendor

// Features by Section
export const ENABLE_COVID_PROTOCOL = false;
export const ENABLE_LIVE_STREAMING = false;
export const ENABLE_GIFT_CARD = false;
export const ENABLE_LOVE_STORY = false;
export const ENABLE_RSVP = true;
export const ENABLE_GUEST_PERSONALIZATION = true;
export const ENABLE_FAQ_SECTION = false;
export const ENABLE_ACCOMODATION_SECTION = false;
export const ENABLE_WEDDING_FILTER = true;
